import {Poste} from "../composants/Poste";
import React, {useEffect, useRef, useState} from "react";
import HttpClient from "../httpClient";
import {PosteModel} from "../models/PosteModel";
import {Navigate, useNavigate} from "react-router-dom";
import {getBackUrl} from "../utils/urlUtils";
import "./css/home.css"
import {BaseButton} from "../composants/designSystem/BaseButton";

interface Props  {
    isMyPost?: boolean
}


export function Home({isMyPost}:Props){

    const [page, setPage] = useState<number>(0)
    const [postesData, setPosteData] = useState<PosteModel[]>([])
    const [error401, setError401] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(true)
    const [EndofData, setEndOfData] = useState<boolean>(false)
    const [category, setCategory] = useState<string>()

    const navigate = useNavigate();


    const handleScroll = () => {
        const position = window.pageYOffset;
        const max = (document.documentElement.scrollHeight - document.documentElement.clientHeight)
        if(position >= max){
            setRefresh(true)
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        setPosteData([])
        setPage(0)
        setRefresh(true)

    }, [category]);


    useEffect(() => {


        if(!refresh) return

        const url = isMyPost ? "http://"+getBackUrl()+"/postes/my" : "http://"+getBackUrl()+"/postes?page="+page + (category ? "&category=" + category : "")

        HttpClient.get(url).then((r) => {
            if(Array.isArray(r.data) && r.data.length === 0){
                setEndOfData(true)
                setRefresh(false)
                return
            }
            setPosteData([...postesData, ...r.data])
            const pageNb = page + 1
            setPage(pageNb)
            setRefresh(false)
        }).catch((error) => {
            setError401(true)
        })

    }, [refresh, category]);


    return(
        <>
            {error401 &&
                <Navigate to="/connexion" replace={true} />
            }


            <div className={"containerPoste"}>

                {
                    !isMyPost &&
                    <div className={"filtreHome"}>
                        <BaseButton click={() => setCategory("LONG")}>Vidéo longue</BaseButton>
                        <BaseButton click={() => setCategory("SHORT")}>Short</BaseButton>
                        <BaseButton click={() => setCategory("THUMBNAILS")}>Miniature</BaseButton>
                    </div>

                }
                {postesData.map((value) => (
                    <div onClick={() => {
                        navigate("/app/details/" + value.id)
                    }}>
                        <Poste content={value}/>
                    </div>
                ))}
                {EndofData &&
                    <p style={{textAlign: "center"}}>Il n'y as plus de postes a charger !</p>
                }
            </div>


        </>

    )
    }
