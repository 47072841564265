
export function Cgu(){
    return (
        <div style={{padding: '20px', fontFamily: 'Arial, sans-serif'}}>
            <h1 style={{margin:25}}>Conditions Générales d'Utilisation (CGU) de GamaPro</h1>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>1. Introduction</h2>
                <p>Bienvenu sur GamaPro. En utilisant notre site et nos services, vous acceptez les présentes
                    Conditions Générales d'Utilisation (CGU). Si vous n'acceptez pas ces termes, l'accès à notre site vous sera impossible.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>2. Services Proposés</h2>
                <p>GamaPro (accessible à l'adresse <a href="https://app.gama-studio.com">app.gama-studio.com</a>) est
                    une application qui permet aux utilisateurs de recevoir des feedbacks sur leurs vidéos ou miniatures
                    YouTube. À l'avenir, nous envisageons d'ajouter des publicités Google Ads et des fonctionnalités
                    payantes gérées via PayPal.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>3. Données Collectées</h2>
                <p style={{margin:10}}>Pour utiliser nos services, nous collectons les données suivantes :</p>
                <ul style={{margin:10}}>
                    <li style={{color:"#FFF"}}>Adresse e-mail : utilisée pour l'authentification et l'envoi de newsletters.</li>
                </ul>
                <p>Nous nous engageons à ne pas collecter d'autres données personnelles sans votre consentement.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>4. Utilisation des Données</h2>
                <p style={{margin:10}}><strong>Authentification :</strong> Votre adresse e-mail est utilisée pour créer et gérer votre
                    compte utilisateur sur GamaPro.</p>
                <p style={{margin:10}}><strong>Newsletter :</strong> En fournissant votre adresse e-mail, vous acceptez de recevoir des
                    newsletters et des communications relatives à nos services. Vous pouvez vous désabonner à tout
                    moment en suivant le lien de désinscription inclus dans chaque e-mail.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>5. Protection des Données</h2>
                <p>Nous nous engageons à protéger vos données personnelles et à respecter votre vie privée. Vos
                    informations personelles ne seront pas partagées avec des tiers sans votre consentement, sauf si la loi
                    l'exige.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>6. Modifications des CGU</h2>
                <p> Nous nous réservons le droit de modifier ces CGU à tout moment. Toute modification sera publiée sur
                    cette page et vous en serez informé par e-mail. En continuant à utiliser nos services après la
                    publication des modifications, vous acceptez les nouvelles conditions.</p>
            </section>

            <section style={{margin:25}}>
                <h2 style={{margin:10}}>7. Contact</h2>
                <p>Pour toute question ou demande concernant ces CGU, veuillez nous contacter à l'adresse suivante : studio.gama@outlook.fr</p>
            </section>

            <p style={{margin:25}}>En utilisant GamaPro, vous acceptez ces Conditions Générales d'Utilisation.</p>
        </div>
    )

}