import {Outlet} from "react-router-dom";
import {MakePost} from "./MakePost";
import {useEffect, useState} from "react";
import HttpClient from "../httpClient";
import {UserModel} from "../models/userModel";
import {Avatar, Link} from "@mui/material";
import {getBackUrl} from "../utils/urlUtils";
import "./css/menu.css"
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {BaseButton} from "./designSystem/BaseButton";
import httpClient from "../httpClient";
import {UserInfo} from "node:os";
import {userInfo} from "../models/userInfo";

export function Menu(){

    const [me , setMe] = useState<UserModel>()
    const [open, setOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<userInfo>();
    const [image, setImage] = useState<string>("")


    useEffect(() => {
        HttpClient.get("http://"+getBackUrl()+"/user/me").then((r) => {
            setMe(r.data[0])

        }).catch((error) => {
            //
        })
        httpClient.get("http://" + getBackUrl() + "/user/info").then(response => {
            setUserInfo(response.data[0]);
            if(response.data[0]["profile_files_id"]){
                httpClient.get("http://" + getBackUrl() + "/files?id=" + response.data[0]["profile_files_id"], {responseType: "blob"}).then(async (r) => {
                    const theImage = URL.createObjectURL(r.data);
                    setImage(theImage)
                })
            }
        });
    }, []);

    return(
        <div>
            <nav>
                <img className={"logoNav"} src={require("../asset/logo.png")}/>
                <div className={"desktopNav"}>
                    <p><a href={"/app"}>Feed</a></p>
                    <p><a href={"/app/my"}>Mes postes</a></p>
                    <p><a href={"https://discord.gg/MEF3vFqu"}>Discord</a></p>
                    <BaseButton click={() => setOpen(true)}>
                        crée un poste
                    </BaseButton>
                </div>
                <div style={{display: "flex", gap:15, alignItems:"center"}}>
                    <p>{me?.points} points</p>
                    {me &&
                        <a href={"/app/me/"}>
                            <Avatar src={image ? image : undefined} style={{
                                width: 35,
                                height: 35,
                                borderRadius: 5
                            }}>{me.username.toUpperCase()[0]}</Avatar>
                        </a>
                    }

                </div>
            </nav>

            <div className={"main"}>
                <Outlet></Outlet>
            </div>

            <footer className={"mobileNav"}>
                <div className={"postBoutton"} onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlus} size={"xl"}/>
                </div>
            </footer>
            <footer className={"desktopFooter"}>
                <div className={"reseaux"}>
                    <a href={"https://discord.gg/MEF3vFqu"}><FontAwesomeIcon size={"2xl"} icon={faDiscord}/> </a>
                    <a href={"https://x.com/StudioGama_off"}><FontAwesomeIcon size={"2xl"} icon={faXTwitter}/> </a>
                    <a href={"https://www.instagram.com/studio_gama_off/?hl=fr"}><FontAwesomeIcon size={"2xl"} icon={faInstagram}/> </a>
                </div>
                <p>© 2024 All Rights Reserved.</p>
                <p>v0.2.0</p>
            </footer>
            <MakePost setOpen={setOpen} open={open}/>
        </div>

    )
}
