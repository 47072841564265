import React, {useState} from "react";
import {
    Alert,
    CircularProgress,
    InputLabel, MenuItem,
    Modal,
} from "@mui/material";
import "./css/MakePost.css"
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {TextFields} from "./designSystem/TextFields";
import {BaseButton} from "./designSystem/BaseButton";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Select} from "./designSystem/Select";


interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    open: boolean
}


export function MakePost({setOpen, open}:Props){

    const [titre, setTitre] = useState("");
    const [content, setContent] = useState("");
    const [category, setCategory] = useState("LONG");

    const [image, setImage] = useState<Blob>();
    const [imageName, setImageName] = useState<string>();
    const [error , setError] = useState<string>()

    const [youtubeLink, setYoutubeLink] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleClose = () => setOpen(false);

    const handleChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if(event.target.files){
            const file = event.target.files[0]
            if(file.size > 2097152){
                alert("File is too big!");
                return
            }
            setImageName(file.name)
            setImage(file)
        }

    }

    const handleSubmit = async () => {

        if (!titre || !content) return
        if (!youtubeLink && !image) return;
        if (youtubeLink) {
            let youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
            if (!youtubeRegex.test(youtubeLink)) return setError("Le lien du youtube n'est pas valide")
        }

        setIsLoading(true)

        let files = null
        if(image != undefined){
           files = await httpClient.post("http://" + getBackUrl() + "/files", {
                file:image
            }, { headers: {"Content-Type": "multipart/form-data"}})

        }

        httpClient.post("http://" + getBackUrl() + "/postes/", {
            titre: titre,
            content: content,
            file_id: files ? Number(files.data) : null,
            url_youtube: youtubeLink,
            category: category
        })
            .then((r) => {
                setIsLoading(false)
                window.location.reload()
            }).catch((error) => {
            if (error.response.status === 401) {
                window.location.reload()
            } else if (error.response.data.message) {
                setError(error.response.data.message)
                setIsLoading(false)
            }
        })


    }


    return(
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                className={"modal"}
            >

                {isLoading ? (
                        <div className={"containerMake"}>
                            <CircularProgress />
                        </div>
                    )
                    :
                    (
                        <div className={"containerMake"}>
                            <h2>Crée un poste</h2>

                            {error &&
                                <Alert severity="error" variant="filled" onClose={() => setError(undefined)}>
                                    {error}
                                </Alert>
                            }

                            <TextFields maxLengt={20} placeholder={"Titre"} value={titre} onChange={(e) => setTitre(e.target.value)}/>
                            <TextFields placeholder={"Description"} value={content} onChange={(e) => setContent(e.target.value)}/>
                            <TextFields placeholder={"https://youtube.com/w...."} value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)}/>
                            <Select onChange={(e) => setCategory(e.target.value)}>
                                <option value={"LONG"}>Vidéo longue</option>
                                <option value={"SHORT"}>Short</option>
                                <option value={"THUMBNAILS"}>Miniature</option>
                            </Select>

                            <div className={"modalButtonMake"}>
                                <BaseButton filebutton={true}>
                                    <input type="file" hidden={true} accept={"image/png, image/jpeg"}  onChange={handleChangeImage}/>
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>{imageName}</p>
                                </BaseButton>
                                <BaseButton click={() => handleSubmit()}>
                                    Crée
                                </BaseButton>
                            </div>

                        </div>
                    )
                }



            </Modal>
        </div>

    )

}
