import {Alert, Button, Checkbox, Link, TextField} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import * as http from "node:http";
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {TextFields} from "../composants/designSystem/TextFields";
import {BaseButton} from "../composants/designSystem/BaseButton";

export function Inscription() {

    const [email, setEmail] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [cgu, setCgu] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [sucess, setsucess] = useState<boolean>(false)


    const navigate = useNavigate();
    const handleInscription = () => {

        if (!cgu) return

        axios.post("http://" + getBackUrl() + "/auth/register", {
            username: username,
            password: password,
            email: email
        }).then(r => {
            setsucess(true)
        }).catch((error) => {
            if(error.response.data.message){
                setError(error.response.data.message)
            }
        })

    }

    return (

        <div className={"main"}>

            <div className={"container"}>

                {error &&
                    <Alert severity="error" variant="filled" onClose={() => setError(undefined)}>
                        {error}
                    </Alert>
                }
                {sucess &&
                    <Alert severity="success" variant="filled" onClose={() => setsucess(sucess)}>
                        Un email de confirmation a été envoyé par mail
                    </Alert>
                }
                <div className={"block"}>
                    <img src={require("../asset/logo.png")} className={"logo"}/>
                    <h2>Inscription</h2>
                </div>
                <div className={"block"}>
                    <TextFields value={email} placeholder={"Email"} onChange={(e) => setEmail(e.target.value)}/>
                    <TextFields value={username} placeholder={"Username"} onChange={(e) => setUsername(e.target.value)}/>
                    <TextFields value={password} placeholder={"************"} password={true} onChange={(e) => setPassword(e.target.value)}/>
                    <div style={{display:"flex",gap:15}}>
                        <TextField type={"checkbox"} onClick={() => setCgu(!cgu)}/>
                        <p>J'accepte les <Link href={"/cgu"}>CGU</Link></p>
                    </div>
                    <BaseButton click={handleInscription}>Inscription</BaseButton>
                </div>
                <div className={"block"}>
                    <p>Tu as déjà un compte ? <b><Link href={"connexion"} style={{textDecoration:"none",color:"#CF8CFF"}}>connecte-toi !</Link></b></p>
                </div>

            </div>

        </div>
    )


}