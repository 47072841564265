import {Button} from "@mui/material";
import {Simulate} from "react-dom/test-utils";
import React, {ChangeEventHandler} from "react";

interface Props {
    children: React.ReactNode
    click?: () => void
    filebutton?: boolean
}

export function BaseButton({ children ,click, filebutton}:Props){
    return(
        <Button variant={"contained"} style={style.button} onClick={click} component={ filebutton ? "label" : "button"}>
            {children}
        </Button>
    )
}

const style = {
    button: {
        backgroundColor: "#CF8CFF",
        color:"#071132",
        fontWeight: 600,
        fontsize:18,
        textTransform: "none" as "none",
        cursor: "pointer"
    }
}