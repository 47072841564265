import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {UserModel} from "../models/userModel";
import {userInfo} from "../models/userInfo";
import { TextFields } from "../composants/designSystem/TextFields";
import {Select} from "../composants/designSystem/Select";
import {Avatar} from "@mui/material";
import {BaseButton} from "../composants/designSystem/BaseButton";

export function Profile() {
    const [user, setUser] = useState<UserModel>();
    const [userInfo, setUserInfo] = useState<userInfo>();
    const [image, setImage] = useState<string>("")
    const [imageForm, setImageForm] = useState<Blob>()
    const navigate = useNavigate();

    const handleChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if(event.target.files){
            const file = event.target.files[0]
            if(file.size > 2097152){
                alert("File is too big!");
                return
            }
            setImage(URL.createObjectURL(file))
            setImageForm(file)
        }

    }

    const handleSave = async () => {
        if(imageForm){
            httpClient.post("http://" + getBackUrl() + "/files", {
                file:imageForm
            }, { headers: {"Content-Type": "multipart/form-data"}}).then(response => {
                let id = response.data
                httpClient.patch("http://" + getBackUrl() + "/user/info", {
                    user_id : user?.id,
                    niche : userInfo?.niche,
                    youtube_chanel : userInfo?.youtube_chanel,
                    description : userInfo?.description,
                    profile_files_id : id
                }).then(response => {
                    setUserInfo(response.data)
                })
            })
        }else {
            httpClient.patch("http://" + getBackUrl() + "/user/info", {
                user_id : user?.id,
                niche : userInfo?.niche,
                youtube_chanel : userInfo?.youtube_chanel,
                description : userInfo?.description,
                profile_files_id : userInfo?.profile_files_id
            }).then(response => {
                setUserInfo(response.data)
            })
        }


    }

    useEffect(() => {
        httpClient.get("http://" + getBackUrl() + "/user/me").then(response => {
            setUser(response.data[0]);
        }).catch((error) => {
            if (error['code'] === 401) {
                navigate("/connexion")
            }
        })
        httpClient.get("http://" + getBackUrl() + "/user/info").then(response => {
            setUserInfo(response.data[0]);
            console.log(response.data[0])
            if(!response.data) {
                httpClient.post("http://" + getBackUrl() + "/user/info").then(response => {
                    setUserInfo(response.data);
                    console.log(response.data)
                });
            }else if(response.data[0]["profile_files_id"]){
                httpClient.get("http://" + getBackUrl() + "/files?id=" + response.data[0]["profile_files_id"], {responseType: "blob"}).then(async (r) => {
                    const theImage = URL.createObjectURL(r.data);
                    setImage(theImage)
                }).catch((error) => {
                    if (error['code'] === 401) {
                        navigate("/connexion")
                    }
                })
            }
        });


    }, []);

    return (
        <div>
            {user && userInfo &&
                <>
                    <div className={"flex flex-col w-1/3 items-center m-auto gap-5 mt-20"}>
                        <div className="flex align-center items-center gap-5">
                            <Avatar src={image ? image : undefined} style={{width: "150px", height: "150px"}}/>
                            <BaseButton filebutton={true}>
                                <input type="file" hidden={true} accept={"image/png, image/jpeg"}
                                       onChange={handleChangeImage}/>
                                Modifier
                            </BaseButton>
                        </div>

                        <div className={"w-full"}>
                            <p>Description :</p>
                            <TextFields placeholder={"description"} value={userInfo.description}
                                        onChange={(e) => setUserInfo({...userInfo, description: e.target.value})}/>
                        </div>

                        <div className={"w-full"}>
                            <p>Lien vers votre youtube channel :</p>
                            <TextFields placeholder={"youtube channel"} value={userInfo.youtube_chanel}
                                        onChange={(e) => setUserInfo({...userInfo, youtube_chanel: e.target.value})}/>
                        </div>

                        <div className={"w-full"}>
                            <p>Choisissez un niche :</p>
                            <Select value={userInfo.niche}
                                    onChange={(e) => setUserInfo({...userInfo, niche: e.target.value})}>
                                <option value={"StoryTelling"}>StoryTelling</option>
                                <option value={"Gaming"}>Gaming</option>
                                <option value={"Sport"}>Sport</option>
                                <option value={"Musique"}>Musique</option>
                                <option value={"Art"}>Art</option>
                                <option value={"Tech"}>Tech</option>
                                <option value={"Buisness"}>Business</option>
                                <option value={"Autre"}>Autre</option>
                            </Select>
                        </div>

                        <BaseButton click={handleSave}>
                            Sauvegarder
                        </BaseButton>
                    </div>

                </>
            }
        </div>
    )

}