import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {PosteModel} from "../models/PosteModel";
import {Avatar} from "@mui/material";
import {FeedBackFields} from "../composants/FeedbackFields";
import {FeedbackModel} from "../models/FeedbackModel";
import {Feedback} from "../composants/Feedback";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./css/details.css"
import {ImgBuffer} from "../composants/designSystem/ImgBuffer";

export function Details(){

    const { id } = useParams();
    const [content, setContent] =  useState<PosteModel>()
    const [feedBack, setfeedBack] =  useState<FeedbackModel[]>([])
    const navigate = useNavigate();
    const [youtubeId, setyoutubeId] = useState<string>()

    const [refresh, setRefresh] = useState<boolean>(true)

    useEffect(() => {

        if(!refresh) return

        httpClient.get("http://"+getBackUrl()+"/feedback?id="+id).then((r) => {
            setfeedBack(r.data)
            setRefresh(false)
        }).catch((error) => {
            if (error['code'] === "ERR_BAD_REQUEST") {
                navigate("/connexion")
            }
        })

    }, [refresh]);


    useEffect(() => {

        httpClient.get("http://"+getBackUrl()+"/postes/"+id).then((r) => {
            setContent(r.data[0])

            let youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
            let match = youtubeRegex.exec(r.data[0].url_youtube)

            if(match && match[1]) {
                setyoutubeId("https://www.youtube.com/embed/" + match[1])
            }
            
        }).catch((error) => {
            if (error['code'] === "ERR_BAD_REQUEST") {
                navigate("/connexion")
            }
        })

    }, [id]);

    return(

        <div className={"main"}>
            <div className={"page"}>
                <nav onClick={() => navigate("/app")}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </nav>
                {
                    content &&
                    <>
                        <div className={"block"}>
                            <h3 className={"blockcontent"}>{content.titre}</h3>
                            <div className={"blockcontent"}>
                                <Avatar style={{
                                    width: 25,
                                    height: 25,
                                    borderRadius: 5
                                }}>{content.username[0].toUpperCase()}</Avatar>
                                <p>{content.username}</p>
                            </div>
                        </div>
                        {
                            youtubeId &&
                            <div className={"blockcontent"}>
                                <iframe width="100%" height="500" src={youtubeId} title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        }
                        {
                            content.file_id &&
                            <div className={"blockcontent"}>
                                <ImgBuffer className={"thumbails"}
                                           src={"http://" + getBackUrl() + "/files?id=" + content.file_id}/>
                            </div>
                        }
                        <div className={"blockcontent"}>
                            <p>Description : <br/> {content.content}</p>
                        </div>
                        <div className={"blockcontent"}>
                            <FeedBackFields setRefresh={setRefresh} postId={content.id}></FeedBackFields>
                        </div>
                        {
                            feedBack.map((v) => (
                                <div className={"feedbackList"}>
                                    <Feedback content={v}/>
                                </div>
                            ))
                        }


                    </>
                }

            </div>
        </div>

    )

}

const styles = {}