import axios from "axios";

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};

const httpClient = axios.create({
    headers:{
        common:{
            "Authorization": getAccessToken()
        }
    }

});


export default httpClient;
