import {Alert, Button, Checkbox, Link} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import * as http from "node:http";
import "./css/login.css"
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {TextFields} from "../composants/designSystem/TextFields";
import {BaseButton} from "../composants/designSystem/BaseButton";

export function Login(){

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<string>()
    const navigate = useNavigate();
    const [emailNonVerif, setEmailNonVerif] = useState<boolean>(false)


    const handleConnexion = () => {

        axios.post("http://"+getBackUrl()+"/auth/login", {
            password: password,
            email: email
        }).then(r => {

            localStorage.setItem('accessToken', "Bearer " + r.data["access_token"]);
            httpClient.defaults.headers.common['Authorization'] = "Bearer " + r.data["access_token"]

            navigate("/app")
        }).catch((error) => {
            if(error.response.data.message){
                setError(error.response.data.message)
            }
        })

    }

    return(

        <div className={"main"}>

            <div className={"container"}>

                <div className={"block"}>
                    <img src={require("../asset/logo.png")} className={"logo"}/>
                    <h2>Connexion</h2>
                </div>
                {error &&
                    <Alert severity="error" variant="filled" onClose={() => setError(undefined)}>
                        {error}
                    </Alert>
                }
                <div className={"block"}>
                    <TextFields value={email} placeholder={"Email"} onChange={(e) => setEmail(e.target.value)}/>
                    <TextFields value={password} placeholder={"************"} password={true} onChange={(e) => setPassword(e.target.value)}/>
                    <BaseButton click={handleConnexion}>Connexion</BaseButton>
                </div>
                <div className={"block"}>
                    <p>Toujours pas de compte ? <b> <Link href={"inscription"} style={{textDecoration:"none",color:"#CF8CFF"}}>inscris-toi !</Link></b></p>
                </div>

            </div>

        </div>
    )


}