import {ChangeEventHandler} from "react";

interface Props {
    placeholder : string
    password?: boolean
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined,
    value: string
    maxLengt?: number
}

export function TextFields({placeholder,password,onChange,value,maxLengt}:Props){

    return(
        <input maxLength={maxLengt} style={style.texfields} placeholder={placeholder} value={value} onChange={onChange} type={password ? "password" : "text"}/>
    )

}

const style = {
    texfields:{
        backgroundColor: "#050C22",
        borderRadius: 10,
        color: "#FFF",
        border: "none",
        outline: "none",
        padding: 15,
        display:"block",
        width: "100%"
    }
}