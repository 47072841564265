import {Avatar, Icon} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-solid-svg-icons";
import {PosteModel} from "../models/PosteModel";
import {useEffect, useState} from "react";
import {getBackUrl} from "../utils/urlUtils";
import {ImgBuffer} from "./designSystem/ImgBuffer";
import httpClient from "../httpClient";
import {useNavigate} from "react-router-dom";

interface Props {
    content: PosteModel
}

export function Poste({content}:Props){

    const [youtubeId, setyoutubeId] = useState<string>()
    const [image, setImage] = useState<string>("")
    const navigate = useNavigate();

    useEffect(() => {
        let youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
        let match = youtubeRegex.exec(content.url_youtube)
        if(match && match[1]) {
            setyoutubeId(match[1])
        }
        httpClient.get("http://" + getBackUrl() + "/files?id=" + content.profile_files_id, {responseType: "blob"}).then(async (r) => {
            const theImage = URL.createObjectURL(r.data);
            setImage(theImage)
        }).catch((error) => {
            if (error['code'] === 401) {
                navigate("/connexion")
            }
        })
    }, []);


    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div style={styles.block}>
                    <Avatar src={image ? image : undefined} style={{width:25, height:25, borderRadius:5}}>{content.username[0].toUpperCase()}</Avatar>
                    <p>{content.username}</p>
                </div>
                <div style={styles.block}>
                    <p>{new Date(content.created_at).toDateString()}</p>
                </div>
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.content}>
                    <h3>{content.titre}</h3>
                    <p>{content.content}</p>
                </div>
                {
                    content.url_youtube &&
                    <div style={styles.content}>
                        <img style={styles.thumbails} src={"https://img.youtube.com/vi/" + youtubeId + "/mqdefault.jpg"}/>
                    </div>
                }
                { content.file_id !== null && content.file_id !== undefined &&
                    <div style={styles.content}>
                        <ImgBuffer style={styles.thumbails}
                                   src={"http://" + getBackUrl() + "/files?id=" + content.file_id}/>
                    </div>
                }
            </div>
            <div style={styles.block}>
                <FontAwesomeIcon icon={faComment} color={"#FFF"} size={"lg"}/>
                <p>{content.nbcom} comentaires</p>
            </div>

        </div>
    )
}

const styles = {

    container:{
        backgroundColor: "#050C22",
        borderRadius: 10,
        width: "100%",
        padding: 10,
        display: "flex",
        flexDirection: "column" as "column",
        gap: 10
    },
    block:{
        backgroundColor: "#0B1029",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        padding: 10,
        gap: 10,
        width:'100%'
    },
    content:{
        backgroundColor: "#0B1029",
        borderRadius: 10,
        alignItems: "center",
        padding: 10,
        gap: 10,
        width:'100%'
    },
    header:{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: 10
    },
    thumbails:{
        borderRadius:5,
        width: "100%"
    },
    contentContainer: {
        display: "flex",
        width: "100%",
        gap: 10
    }

}