import {FeedbackModel} from "../models/FeedbackModel";
import {Avatar} from "@mui/material";

interface Props{
    content: FeedbackModel
}


export function Feedback({content}:Props){


    return(
        <div style={styles.container}>
            <div style={styles.block}>
                <Avatar style={{width:25, height:25, borderRadius:5}}>{content.username[0].toUpperCase()}</Avatar>
                <p>{content.username}</p>
            </div>
            <div>
                <p >{content.feedback}</p>
                <p style={{fontSize:10}}>{new Date(content.created_at).toLocaleDateString()}</p>
            </div>
        </div>
    )


}

const styles = {
    container:{
        display: "flex",
        flexDirection: "column" as "column",
        gap: 10,
        backgroundColor: "#050C22",
        padding: 10,
        borderRadius: 10
    },
    block:{
        display: "flex",
        alignItems: "center",
        gap:10
    }
}