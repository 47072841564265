import {FeedbackModel} from "../models/FeedbackModel";
import {Avatar, Button, TextField} from "@mui/material";
import React, {useState} from "react";
import httpClient from "../httpClient";
import {getBackUrl} from "../utils/urlUtils";
import {TextFields} from "./designSystem/TextFields";
import {BaseButton} from "./designSystem/BaseButton";

interface Props{
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
    postId: number
}


export function FeedBackFields({setRefresh,postId}:Props){

    const [feedback, setFeedback] = useState<string>("")

    const handleFeedback = () => {

        if(feedback == "" || feedback == undefined) return

        httpClient.post("http://"+getBackUrl()+"/feedback",{feedback: feedback, post_id: postId}).then((r) => {
            setRefresh(true)
            setFeedback("")
        }).catch((error) => {
            if(error.response.status === 400){
                alert("Certain champs contient des therme inapproprié")
                setFeedback("")
            }
            if(error.response.status === 401){
                window.location.reload()

            }
        })

    }


    return(
        <div style={styles.container}>
            <TextFields placeholder={"Commentaire.."}
                       onChange={(e) => setFeedback(e.target.value)}
                        value={feedback?? ""}
            />
            <BaseButton click={handleFeedback}>
                Envoyer
            </BaseButton>
        </div>
    )


}

const styles = {
    container: {
        gap: 10,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between" as "space-between"
    },
}