import {BaseButton} from "../composants/designSystem/BaseButton";
import "./css/presentation.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {useNavigate} from "react-router-dom";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


export function Presentation(){

    const navigate = useNavigate();


    return(
        <div>
            <nav className={"pres-nav bg-[#00000050]"}>
                <img src={require("../asset/logo.png")} style={{width:72}}/>
                <div>
                    <BaseButton click={() => navigate("inscription")}>Rejoins-nous !</BaseButton>
                </div>

            </nav>
            <div>

                <header className={"pre-header"}>
                    <video style={{margin:"auto", display:"block"}} muted autoPlay loop src={require("../asset/gamapro.mp4")}></video>
                    <div className={"pre-h1"}>
                        <h1>GAMAPRO</h1>
                        <p>Gamapro est un outil qui vous permettra, dans un premier temps, d'obtenir des feedbacks pour vos vidéos gratuitement<br/> afin que votre chaîne YouTube explose !</p>
                        <BaseButton click={() => navigate("inscription")}>GO !</BaseButton>
                    </div>
                </header>


                <section className={"pre-sec-1"}>
                    <Element name="pres">
                        <h2 className={"pre-h2"}>GamaPro c'est ça</h2>
                    </Element>
                    <div className={"pre-fonct text-center"}>
                        <div className={"pre-blockinfo"}>
                            <h3 className={"font-bold text-xl"}>Gratuit</h3>
                            <p>GamaPro est un outil complètement gratuit pour les créateurs de contenu.</p>
                        </div>
                        <div className={"pre-blockinfo"}>
                            <h3 className={"font-bold text-xl"}>Comunautaire</h3>
                            <p>GamaPro vit grâce à la communauté et aux personnes faisant des feedbacks et des posts.</p>
                        </div>
                        <div className={"pre-blockinfo"}>
                            <h3 className={"font-bold text-xl"}>Mise à jour</h3>
                            <p>Les mises à jour de GamaPro sont régulières, nous essayons de répondre au maximum de demandes.</p>

                        </div>
                        <div className={"pre-blockinfo"}>
                            <h3 className={"font-bold text-xl"}>Pour les créateurs</h3>
                            <p>L'outil a été créé par un créateur de contenu pour les créateurs de contenu !</p>
                        </div>
                    </div>
                </section>

                <h2 className={"font-black text-5xl text-center p-5"}>RoadMap</h2>
                <section className={"flex gap-10 justify-center p-5 w-full flex-wrap"} id={"roadmap"}>
                    <div className={"p-5 bg-[#0A1946FF] rounded-xl min-w-60"}>
                        <h3  className={"font-black text-4xl text-center min-w-60"}>2024 Q3</h3>
                        <ul className={"p-5"}>
                            <li>- Profil utilisateur</li>
                            <li>- Système de points ✅</li>
                            <li>- Filtre de posts ✅</li>
                            <li>- Landing pages ✅</li>
                        </ul>
                    </div>

                    <div className={"p-5 bg-[#0A1946FF] rounded-xl"}>
                        <h3 className={"font-black text-4xl text-center min-w-60"}>2024 Q4</h3>
                        <ul className={"p-5"}>
                            <li>- Conférences live </li>
                            <li>- Filtré par niche</li>
                            <li>- Analytique de vidéos </li>
                            <li>- Voir les profils des autres</li>
                            <li>- Avis sur les profils</li>
                            <li>- Ajouts des niveaux</li>
                        </ul>
                    </div>
                </section>
            </div>
            <footer className={"bg-[#0A1946FF] p-10 flex flex-col gap-5 mt-5"}>
                <div className={" gap-8 flex"}>
                    <img src={require("../asset/logo.png")} style={{width:45}}/>
                    <BaseButton click={() => navigate("inscription")}>Rejoindre GamaPro</BaseButton>
                </div>
                <div className={"reseaux"}>
                    <a href={"https://discord.gg/MEF3vFqu"}><FontAwesomeIcon size={"2xl"} icon={faDiscord}/> </a>
                    <a href={"https://x.com/StudioGama_off"}><FontAwesomeIcon size={"2xl"} icon={faXTwitter}/> </a>
                    <a href={"https://www.instagram.com/studio_gama_off/?hl=fr"}><FontAwesomeIcon size={"2xl"} icon={faInstagram}/> </a>
                </div>

            </footer>
        </div>
    )
}