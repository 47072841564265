import React, {ChangeEventHandler} from "react";

interface Props{
    children: React.ReactNode
    onChange?: ChangeEventHandler<HTMLSelectElement> | undefined
    value?: string | number | readonly string[] | undefined
}

export function Select({children,onChange, value}: Props) {

    return(
        <select style={styles.container} onChange={onChange} value={value}>
            {children}
        </select>
        )

}

const styles = {
    container: {
        backgroundColor: "#050C22",
        borderRadius: 10,
        color: "#FFF",
        border: "none",
        outline: "none",
        padding: 15,
        display:"block",
        width: "100%"
    }
}