import React, {useEffect, useState} from "react";
import httpClient from "../../httpClient";
import {getBackUrl} from "../../utils/urlUtils";
import {useNavigate} from "react-router-dom";

interface Props {

    src: string
    style?: React.CSSProperties
    className?: string

}

export function ImgBuffer ({src, style, className}:Props){

    const [base64, setBase64] = useState<string>("")
    const navigate = useNavigate();

    useEffect(() => {
        httpClient.get(src, {responseType: "blob"}).then(async (r) => {
            const theImage = URL.createObjectURL(r.data);
            setBase64(theImage)
        }).catch((error) => {
            if (error['code'] === 401) {
                navigate("/connexion")
            }
        })
    }, [src]);

    return (
        <>
            <img  style={style} className={className} src={base64}/>
        </>
    )
}