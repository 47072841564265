import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import * as ReactDOM from "react-dom/client";
import {Menu} from "./composants/Menu";
import {Home} from "./pages/Home";
import "./index.css"
import {Inscription} from "./pages/Inscription";
import {Login} from "./pages/login";
import {Cgu} from "./pages/Cgu";
import {Details} from "./pages/Details";
import {Presentation} from "./pages/Presentation";
import {Profile} from "./pages/Profile";

  const router = createBrowserRouter([
    {
      path: "/app",
      element: <Menu/>,
      children: [
        {
          path: "",
          element: <Home/>
        },
        {
          path: "my",
          element: <Home isMyPost={true}/>
        },
        {
          path: "me",
          element: <Profile/>
        },
      ]
    },
    {
      path: "/",
      element: <Presentation/>,
    },
    {
      path: "app/details/:id",
      element: <Details/>
    },
    {
      path: "/connexion",
      element: <Login/>,
    },
    {
      path: "/inscription",
      element: <Inscription/>
    },
    {
      path: "/cgu",
      element: <Cgu/>
    },
  ]);


  const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
  );

  root.render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
  );
